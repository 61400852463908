import Repository from './Repository';
import {AxiosResponse} from "axios";

const resource = '/auth';

export default new class{
    getListLoi130(st: string, loai: number, t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/check130/${st}/${loai}/${t1}/${t2}`);
    }
    getListBA(t1: number, t2: number, khoaPhong_Id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listBA/${t1}/${t2}/${khoaPhong_Id}`);
    }
    getListKS(time: number, khoaPhong_Id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/kSBA/${khoaPhong_Id}/${time}`);
    }
}
