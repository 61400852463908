

import { computed, ref } from 'vue'
import moment from 'moment';
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import check130Repository from "@/services/Check130Repository";
import {FilterMatchMode} from "primevue/api"
import {Dulieu130} from "@/models/dulieu130";
import QLTSRepository from "@/services/QLTSRepository";
import {Dulieu130_Khoa} from "@/models/dulieu130_khoa";
import {DanhSachKySo} from "@/models/danhSachKySo";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listLoi130  = ref([] as Dulieu130[]);
    const listLoi130_BA = ref([] as Dulieu130[]);
    const maBenhAn = ref("");
    const loadingBar = ref(false);
    const date = ref();
    const date1 = ref();
    const dateKySo = ref();
    const loai = ref(1);
    const khoaPhong_id = ref(0);
    const khoaPhongKySo_id = ref(0);
    const listDuLieu_khoa = ref([] as Dulieu130_Khoa[]);
    const listDuLieu_KySo = ref([] as DanhSachKySo[]);

    const dsKhoaPhong = ref([
      {label: "Khoa Ngoại Phụ", value: 22},
      {label: "Khoa Nội Nhi", value: 23},
      {label: "Khoa Nội Tổng Hợp", value: 28},
      {label: "Khoa Nội Tổng Hợp 2", value: 146},
      {label: "Khoa Ngoại Phụ 2", value: 164},
      {label: "Khoa Châm Cứu Dưỡng Sinh", value: 66},
      {label: "Khoa Châm Cứu Dưỡng Sinh Cơ Sở 2", value: 69},
    ]);
    // YHCT

    // const dsKhoaPhong = ref([
    //   {label: "Khoa Nhi Tổng hợp", value: 28},
    //   {label: "KHOA PT - GÂY MÊ VÀ HSTC (SICU)", value: 37},
    //   {label: "Khoa Sinh", value: 43},
    //   {label: "Khoa Hậu sản - Dưỡng nhi", value: 46},
    //   {label: "Khoa Phụ ngoại", value: 48},
    //   {label: "Khoa Khám Đa Khoa - Cấp cứu", value: 1},
    //   {label: "PK NHI 1", value: 3},
    //   {label: "PK PHỤ KHOA 1", value: 7},
    //   {label: "PK KHÁM THAI 1", value: 9},
    //   {label: "PK KHOA SINH", value: 10},
    //   {label: "Khoa Giải phẫu bệnh", value: 63},
    //   {label: "KHÁM SỨC KHỎE", value: 67},
    //   {label: "PK TIÊM CHỦNG", value: 69},
    //   {label: "Khoa Dinh Dưỡng", value: 77},
    // ]); // PSN2


    // const dsKhoaPhong = ref([
    //   {label: "Phòng khám Nội 1", value: 547},
    //   {label: "Phòng khám Nội 2", value: 548},
    //   {label: "Phòng khám Nội 3", value: 549},
    //   {label: "Phòng khám Nội 4", value: 550},
    //   {label: "Phòng khám Nội 5", value: 551},
    //   {label: "Phòng khám Nội 6", value: 552},
    //   {label: "Phòng khám Nội 7", value: 553},
    //   {label: "Phòng khám Nội 8(Tâm Thần)", value: 554},
    //   {label: "Phòng khám Nội 9(Lao)", value: 555},
    //   {label: "Phòng khám Ngoại", value: 556},
    //   {label: "Phòng khám Mắt", value: 557},
    //   {label: "Phòng khám TMH", value: 558},
    //   {label: "Phòng khám RHM", value: 559},
    //   {label: "Phòng khám Sản", value: 560},
    //   {label: "Phòng khám YHCT-PHCN", value: 561},
    //   {label: "Khoa Nội Nhi Lây", value: 562},
    //   {label: "Khoa Y học cổ truyền và Phục hồi chức năng", value: 563},
    //   {label: "Khoa Ngoại - Sản", value: 564},
    //   {label: "Khoa Liên Chuyên khoa", value: 565},
    //   {label: "Ngoại trú Khoa Ngoại", value: 571},
    //   {label: "Khoa Hồi Sức Cấp Cứu", value: 573},
    //   {label: "Khoa Lão", value: 576},
    //   {label: "Phòng Cấp Cứu Nội", value: 615},
    //   {label: "Phòng Cấp Cứu Ngoại", value: 616},
    //   {label: "Ngoại trú YHCT - PHCN", value: 618},
    //   {label: "Ngoại Trú Liên Chuyên Khoa", value: 623},
    // ]); //ngu hanh son

    const loadLoiDuLieu130 = () => {
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% loadLoiDuLieu130: " );
      loadingBar.value = true;
      listLoi130.value = [];
      if((date1.value == null)&&(loai.value == 2)){
        loadingBar.value = false;
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:"Vui lòng lựa chọn khoảng thời gian khám bệnh(đối với khám bệnh ngoại trú)",
          life: 2000
        });
      }
      else if(loai.value == 2) {
        check130Repository.getListLoi130(maBenhAn.value, loai.value, date1.value[0] / 1000, date1.value[1] / 1000)
            .then((response) => {
              listLoi130.value = response.data;
              loadingBar.value = false;
            })
            .catch(err => {
              loadingBar.value = false;
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            });
      }
      else{
        check130Repository.getListLoi130(maBenhAn.value, loai.value, 0, 0)
            .then((response) => {
              listLoi130.value = response.data;
              loadingBar.value = false;
            })
            .catch(err => {
              loadingBar.value = false;
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            });
      }
    }


    const loadListBA= () => {
      loadingBar.value = true;
      listLoi130_BA.value = [];
      if(date.value != null)
      check130Repository.getListBA(date.value[0]/1000, date.value[1]/1000, khoaPhong_id.value)
          .then((response) => {
            listDuLieu_khoa.value = response.data;
            loadingBar.value = false;
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const loadListKySo= () => {
      loadingBar.value = true;
      listDuLieu_KySo.value = [];
      if(dateKySo.value != null)
      check130Repository.getListKS((dateKySo.value/1000)*1000, khoaPhongKySo_id.value)
          .then((response) => {
            listDuLieu_KySo.value = response.data;
            loadingBar.value = false;
            console.log(" Load du lieu ky so listDuLieu_KySo: " + JSON.stringify(listDuLieu_KySo.value));
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('HH:mm DD/MM/YYYY');
    };

    const valid = computed(()=> true);

    const expandedRowGroups = ref();

    const expandedRows = ref();
    return {
      error,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      loadingBar,
      loadLoiDuLieu130,
      listLoi130,
      maBenhAn,
      expandedRowGroups,
      date,
      loadListBA,
      listLoi130_BA,
      listDuLieu_khoa,
      khoaPhong_id,
      dsKhoaPhong,
      expandedRows,
      date1,
      loai,
      khoaPhongKySo_id,
      dateKySo,
      loadListKySo,
      listDuLieu_KySo,
    }
  }
}

