<template xmlns="http://www.w3.org/1999/html">
<!--  <div v-if="isLoggedIn" class="app-container">-->
<!--    <Toast />-->
<!--&lt;!&ndash;    <transition name="layout-sidebar">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="sidebars" >&ndash;&gt;-->
<!--&lt;!&ndash;        <TopNavBar :isLoggedIn="isLoggedIn"/>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </transition>&ndash;&gt;-->
<!--    <div id="main_page">-->
<!--      <div class="main_content">-->
<!--        <div class="top_bar" :isLoggedIn="isLoggedIn" >-->
<!--&lt;!&ndash;          <div class="infor" v-if="tenCoSo">&ndash;&gt;-->
<!--&lt;!&ndash;            <h3 style="white-space:pre">{{convertString(tenCoSo)}}</h3>&ndash;&gt;-->
<!--&lt;!&ndash;            <p><span>Địa chỉ: {{diaChi}}</span></p>&ndash;&gt;-->
<!--&lt;!&ndash;            <p><span style="white-space:pre">Điện thoại: {{convertString(sdt)}}</span> </p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="user_box">&ndash;&gt;-->
<!--&lt;!&ndash;            <img src="./assets/bs2.png">&ndash;&gt;-->
<!--&lt;!&ndash;            <span>{{ fullName }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <p><span>{{team}}</span></p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="bymenu">&ndash;&gt;-->
<!--&lt;!&ndash;            <i class="pi pi-list"></i>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <router-view/>-->
<!--      </div>-->

<!--    </div>-->
<!--&lt;!&ndash;    <div id="footer" v-if="tenCoSo">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="main_ft">&ndash;&gt;-->
<!--&lt;!&ndash;        <p>Design by @QuocHoang</p>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->

<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
<!--  <div v-else>-->
    <Toast />
    <check130 />
<!--  </div>-->

</template>

<!--<style lang="scss">-->
//.app-container {
//  margin: 0 auto;
//  max-width: 1400px;
//  width: 100%;
//  height: inherit;
//  background: #F7F8F9;
//  .app-content {
//    margin: 8px;
//  }
//}
<!--</style>-->
<style lang="scss">
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import LoginPage from "@/pages/LoginPage";
import check130 from "@/pages/Check130";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  components: {TopNavBar, check130},
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);
    const fullName = computed(() => store.state.fullName);
    const team = computed(() => store.state.team);
    console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission APPPPPPP: ");

    return {
      isLoggedIn,
      fullName,
      team,
    }
  },
  updated(){
    jQuery(".byhome").click(function(){
      jQuery(this).toggleClass( "hide-bar" );
      jQuery(".sidebars").toggleClass( "hide-bar" );
      jQuery("#main_page").toggleClass( "hide-bar" );
      jQuery("#footer").toggleClass( "hide-bar" );
    });
  }
}
</script>
